<template>
  <div>
    <PriceFilter />
    <PricesTable catalog-type="cw" :loading="loading" />
  </div>
</template>

<script>
import PriceFilter from "@/components/prices/PricesFilter"
import PricesTable from "@/components/prices/PricesTable"

export default {
  name: "Prices",
  components: {
    PriceFilter,
    PricesTable,
  },
  data() {
    return {
      loading: false,
    }
  },
  async mounted() {
    await this.getPrices()
  },
  methods: {
    async getPrices() {
      this.loading = true
      await this.$store.dispatch("prices/fetchPrices", "local")
      this.loading = false
    },
  },
}
</script>
